<template>
  <div id="all-events-list">
    <v-overlay :value="ProgressFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="editRecordPrompt" persistent max-width="80%">
      <event-plan-edit
        :editRecordPrompt="editRecordPrompt"
        @hideEditRecordPrompt="hideEditRecordPrompt"
        :recordData="selectedData"
        v-if="editRecordPrompt"
      ></event-plan-edit>
    </v-dialog>
    <v-row wrap>
      <v-col align="center" cols="12" md="12">
        <v-btn
          @click.prevent="backPage"
          color="primary"
          elevation="30"
          shaped
          tile
          small
          class="font-size-h6 mr-3 white--text"
        >
          <v-icon dark>mdi-arrow-left-circle</v-icon>&nbsp; Back
        </v-btn>
        <v-btn
          elevation="30"
          shaped
          tile
          small
          class="font-size-h6 mr-3 white--text"
          @click.prevent="refreshPageData"
          color="#EDBE38"
        >Refresh</v-btn>
      </v-col>
    </v-row>
    <p></p>
    <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
      <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
    </div>
    <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
      <v-card>
        <v-card-title class="text-h5">
          <h2>{{ PageTitle }}</h2>
        </v-card-title>
        <v-card-text>
          <v-container class="py-0">
            <v-row wrap>
              <v-col align="start" cols="12" md="12">
                <p>
                  <span class="text-danger">*</span> indicates required field
                </p>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Event Name
                  </h6>
                </label>
                <v-text-field v-model="EventName" :rules="EventNameRules" outlined dense required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    Event Description
                    <small>(optional)</small>
                  </h6>
                </label>
                <v-text-field
                  v-model="EventDescription"
                  :rules="EventDescriptionRules"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Event Start Date
                  </h6>
                </label>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="EventStartDate"
                      :rules="EventStartDateRules"
                      readonly
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="EventStartDate" @input="menu1 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Event End Date
                  </h6>
                </label>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="EventEndDate"
                      :rules="EventEndDateRules"
                      readonly
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="EventEndDate" @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Event Total Limit
                  </h6>
                </label>
                <v-text-field
                  v-model="EventTotalLimit"
                  :rules="EventTotalLimitRules"
                  required
                  outlined
                  dense
                  min="0"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Event Max Limit
                  </h6>
                </label>
                <v-text-field
                  v-model="EventMaxLimit"
                  :rules="EventMaxLimitRules"
                  required
                  outlined
                  dense
                  min="0"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3" v-if="AdminFlag">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Event Invoice Prefix
                  </h6>
                </label>
                <v-text-field
                  v-model="EventInvoicePrefix"
                  :rules="EventInvoicePrefixRules"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3" v-if="AdminFlag">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Event Registration Link
                  </h6>
                </label>
                <v-text-field
                  v-model="EventRegistrationLink"
                  :rules="EventRegistrationLinkRules"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Event Status
                  </h6>
                </label>
                <v-switch
                  inset
                  v-model="EventStatus"
                  :label="`${
                            EventStatus
                              ? 'Active'
                              : 'Inactive'
                          }`"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Event Payable
                  </h6>
                </label>
                <v-switch
                  inset
                  v-model="EventPayable"
                  :label="`${
                            EventPayable
                              ? 'Yes'
                              : 'No'
                          }`"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <br />
      <br />
      <v-card>
        <v-card-title class="text-h5">
          <h2>Event Plans</h2>
        </v-card-title>
        <v-card-text>
          <v-container class="py-0">
            <div class="d-flex justify-content-center mb-3" v-if="TableLoadingFlag">
              <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
            </div>
            <v-row wrap>
              <v-col align="center" cols="12" md="12" v-if="tableData1.length > 0">
                <h6>{{ tableData1.length }} records found.</h6>
              </v-col>
              <v-col align="center" cols="12" md="12" v-if="tableData1.length <= 0">
                <h6>No records found.</h6>
              </v-col>
              <v-col align="center" cols="12" md="12" v-if="tableData1.length > 0">
                <v-data-table
                  class="elevation-1"
                  loading-text="Loading... Please wait"
                  v-model="selected"
                  :search="search"
                  :item-key="tableOptions1.ItemKey"
                  :single-select="tableOptions1.SingleSelectFlag"
                  :headers="tableColumns1"
                  :items="tableData1"
                  :items-per-page="tableOptions1.ItemsPerPage"
                  :footer-props="tableOptions1.FooterProps"
                >
                  <template v-slot:item.EventPlansContent="{ item }">
                    <div v-html="item.EventPlansContent"></div>
                  </template>
                  <template v-slot:item.EventPlansStatusTxt="{ item }">
                    <v-chip
                      :color="getActiveStatusColor(item.EventPlansStatusTxt)"
                      draggable
                      dark
                    >{{ item.EventPlansStatusTxt }}</v-chip>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom v-if="item.EditFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="editData(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >mdi-file-document-edit</v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.DeleteFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="red"
                          @click="deleteConfirmAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >mdi-delete</v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <br />
      <br />
      <v-row wrap>
        <v-col align="center" cols="12" md="12">
          <v-btn
            :disabled="!valid1"
            @click.prevent="submitForm"
            :loading="SubmitFlag"
            elevation="30"
            shaped
            tile
            small
            color="primary"
            class="font-size-h6 px-10 mr-3 white--text"
          >Submit</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import EventPlanEdit from "@/view/pages/erp/events/all-events/event-plans/EventPlanEdit.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: { EventPlanEdit },
  props: {},
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ProgressFlag: false,
      TableLoadingFlag: false,

      editRecordPrompt: false,

      RoleId: "",
      AdminFlag: false,

      menu1: false,
      menu2: false,

      row: {},

      EventId: "",
      EventName: "",
      EventDescription: "",
      EventRegistrationLink: "",
      EventTotalLimit: 0,
      EventMaxLimit: 0,
      EventPayable: 0,
      EventStatus: 0,
      EventStatusTxt: "",
      EventStartDate: new Date().toISOString().slice(0, 10),
      EventEndDate: new Date().toISOString().slice(0, 10),
      EventInvoicePrefix: "",
      EventPlans: [],

      EventNameRules: [v => !!v || "Event Name is required"],
      EventDescriptionRules: [],
      EventRegistrationLinkRules: [
        v => !!v || "Event Registration Link is required"
      ],
      EventStartDateRules: [v => !!v || "Event Start Date is required"],
      EventEndDateRules: [v => !!v || "Event End Date is required"],
      EventTotalLimitRules: [],
      EventMaxLimitRules: [],
      EventInvoicePrefixRules: [v => !!v || "Event Invoice Prefix is required"],

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  mounted() {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        this.RoleId = RoleId;
        this.AdminFlag = RoleId == 2 ? true : false;

        var EventId = this.$route.query.v1;
        EventId = EventId == (null || undefined) ? 0 : EventId;
        console.log({ EventId });
        this.EventId = EventId;

        this.getEventPlanDetails();
        this.getEventDetails();
      }
    },
    row: function() {
      console.log("watch row");

      this.EventName = this.row.EventsName;
      this.EventDescription = this.row.Description;
      this.EventTotalLimit = this.row.TotalLimit;
      this.EventMaxLimit = this.row.MaxLimit;
      this.EventPayable = this.row.EventPayable;
      this.EventStatus = this.row.ActiveStatus;
      this.EventStatusTxt = this.row.ActiveStatusTxt;
      this.EventStartDate = this.row.StartDate;
      this.EventEndDate = this.row.EndDate;
      this.EventInvoicePrefix = this.row.InvoicePrefix;
      this.EventRegistrationLink = this.row.RegistrationLink;
    }
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "events",
        Action: "edit"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getEventPlanDetails() {
      console.log("getEventPlanDetails is called");

      var EventId = this.EventId;
      console.log("EventId=" + EventId);

      if (EventId != "") {
        this.TableLoadingFlag = true;
        this.tableData1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/event-plans/lists";
        var upload = {
          UserInterface: 1,
          Event: EventId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.TableLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            console.log({ records });
            var options = records.TableOptions;
            console.log({ options });
            thisIns.tableOptions1 = options;

            thisIns.tableColumns1 = records.TableHeader;

            if (flag == 1) {
              thisIns.tableData1 = records.TableData;
              // thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.TableLoadingFlag = false;
          });
      } else {
        var message = "";
        if (EventId == "") {
          message += "Event Details Not found.";
        }
        this.sweetAlert("error", message, false);
      }
    },
    getEventDetails() {
      console.log("getEventDetails is called");

      var EventId = this.EventId;
      console.log("EventId=" + EventId);

      if (EventId != "") {
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/events/show";
        var upload = {
          UserInterface: 1,
          EventCode: EventId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            console.log({ records });

            if (flag == 1) {
              thisIns.row = records;
              // thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
            thisIns.toast("error", error);
          });
      } else {
        var message = "";
        if (EventId == "") {
          message += "Event Details Not found.";
        }
        this.sweetAlert("error", message, false);
      }
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.editRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one event to edit";
        this.toast("error", message);
      }
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.getEventPlanDetails();
    },
    deleteConfirmAlert(tr) {
      console.log("deleteConfirmAlert called");
      var message = "";
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/> Event Name <b> " + tr.EventPlansName + "</b>";
        htmlTxt += "<br/> Amount <b> " + tr.EventPlansAmountTxt + "</b>";
        htmlTxt += "<br/> Tax <b> " + tr.EventPlansTax + "%</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6"
        }).then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(tr) {
      console.log("deleteData is called");

      console.log("EventId=" + EventId);

      var PlanId = tr.EventPlansId;
      var EventId = tr.EventId;

      console.log("PlanId=" + PlanId + "EventId=" + EventId);

      if (PlanId > 0 && EventId > 0) {
        this.ProgressFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/event-plans/destroy";
        var upload = {
          UserInterface: 1,
          EventPlan: PlanId,
          Event: EventId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ProgressFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.getEventPlanDetails();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.ProgressFlag = false;
            thisIns.toast("error", error);
          });
      } else {
        var message = "Event Id should not be empty. ";
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var EventId = this.EventId;

      console.log("EventId=" + EventId);

      if (validate1 && EventId != "") {
        var EventStatus = this.EventStatus ? 1 : 0;
        var EventPayable = this.EventPayable ? 1 : 2;
        var EventTotalLimit =
          this.EventTotalLimit == "" ? 0 : this.EventTotalLimit;
        var EventMaxLimit = this.EventMaxLimit == "" ? 0 : this.EventMaxLimit;

        var upload = {
          UserInterface: 1,
          Event: EventId,
          EventName: this.EventName,
          EventDescription: this.EventDescription,
          EventStartDate: this.EventStartDate,
          EventEndDate: this.EventEndDate,
          EventTotalLimit: EventTotalLimit,
          EventMaxLimit: EventMaxLimit,
          EventPayable: EventPayable,
          EventStatus: EventStatus,
          EventInvoicePrefix: this.EventInvoicePrefix,
          EventRegistrationLink: this.EventRegistrationLink
        };
        console.log({ upload });

        this.SubmitFlag = true;
        this.ProgressFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/events/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.ProgressFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.backPage();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.ProgressFlag = false;
            thisIns.SubmitFlag = false;
            thisIns.toast("error", error);
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        if (EventId == "") {
          message += "Event Details Not found.";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#all-events-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>