<template>
  <div id="all-events-list">
    <v-overlay :value="ProgressFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ PageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
          <v-container class="py-0">
            <v-row wrap>
              <v-col cols="12" md="12">
                <h6>
                  <span class="text-danger">*</span> indicates required
                  field
                </h6>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Plan Name
                  </h6>
                </label>
                <v-text-field v-model="PlanName" :rules="PlanNameRules" outlined dense required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    Plan Description
                    <small>(optional)</small>
                  </h6>
                </label>
                <v-text-field
                  v-model="PlanDescription"
                  :rules="PlanDescriptionRules"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Plan Amount
                  </h6>
                </label>
                <v-text-field
                  v-model="PlanAmount"
                  :rules="PlanAmountRules"
                  required
                  outlined
                  dense
                  min="0"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Plan Tax
                  </h6>
                </label>
                <v-text-field
                  v-model="PlanTax"
                  :rules="PlanTaxRules"
                  required
                  outlined
                  dense
                  min="0"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Plan Max Limit
                  </h6>
                </label>
                <v-text-field
                  v-model="PlanMaxLimit"
                  :rules="PlanMaxLimitRules"
                  required
                  outlined
                  dense
                  min="0"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Plan Recommended
                  </h6>
                </label>
                <v-switch
                  inset
                  v-model="PlanRecommended"
                  :label="`${
                            PlanRecommended
                              ? 'Yes'
                              : 'No'
                          }`"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Plan Status
                  </h6>
                </label>
                <v-switch
                  inset
                  v-model="PlanStatus"
                  :label="`${
                            PlanStatus
                              ? 'Active'
                              : 'Inactive'
                          }`"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="12">
                <label>
                  <h6>
                    Plan Content
                    <small>(optional)</small>
                  </h6>
                </label>
                <tiptap-vuetify v-model="PlanContent" :extensions="extensions" />
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  elevation="30"
                  shaped
                  tile
                  small
                  color="#1db954"
                  class="font-size-h6 px-10 mr-3 white--text"
                >Submit</v-btn>
                <v-btn
                  @click.prevent="closePrompt"
                  elevation="30"
                  shaped
                  tile
                  small
                  color="red"
                  class="font-size-h6 px-10 mr-3 white--text"
                >Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  mixins: [common],
  components: { TiptapVuetify },
  props: {
    editRecordPrompt: {
      type: Boolean,
      required: true
    },
    recordData: {
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      ProgressFlag: false,

      extensions: [
        History,
        Blockquote,
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        HorizontalRule,
        Paragraph,
        HardBreak
      ],

      PlanName: "",
      PlanDescription: "",
      PlanContent: "",
      PlanAmount: 0,
      PlanTax: 0,
      PlanMaxLimit: 0,
      PlanRecommended: 0,
      PlanStatus: 0,

      PlanNameRules: [v => !!v || "Plan Name is required"],
      PlanDescriptionRules: [],
      PlanContentRules: [],
      PlanAmountRules: [v => !!v || "Plan Amount is required"],
      PlanTaxRules: [],
      PlanMaxLimitRules: [],

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  mounted() {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.searchForm();
      }
    }
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "events",
        Action: "edit_1"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    searchForm() {
      console.log("searchForm is called");

      this.EventId = this.recordData.EventId;
      this.PlanId = this.recordData.EventPlansId;
      this.PlanName = this.recordData.EventPlansName;
      this.PlanDescription = this.recordData.EventPlansDescription;
      this.PlanContent = this.recordData.EventPlansContent;
      this.PlanAmount = this.recordData.EventPlansAmount;
      this.PlanTax = this.recordData.EventPlansTax;
      this.PlanMaxLimit = this.recordData.EventPlansMaxLimit;
      this.PlanRecommended = this.recordData.EventPlansRecommended;
      this.PlanStatus = this.recordData.EventPlansStatus;
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var PlanId = this.PlanId;
      var EventId = this.EventId;

      console.log("PlanId=" + PlanId + "EventId=" + EventId);

      if (validate1 && PlanId != "" && EventId != "") {
        var PlanTax = this.PlanTax == "" ? 0 : this.PlanTax;
        var PlanMaxLimit = this.PlanMaxLimit == "" ? 0 : this.PlanMaxLimit;
        var PlanStatus = this.PlanStatus ? 1 : 0;
        var PlanRecommended = this.PlanRecommended ? 1 : 0;

        var upload = {
          UserInterface: 1,
          EventPlan: PlanId,
          Event: EventId,
          PlanName: this.PlanName,
          PlanDescription: this.PlanDescription,
          PlanContent: this.PlanContent,
          PlanAmount: this.PlanAmount,
          PlanTax: PlanTax,
          PlanMaxLimit: PlanMaxLimit,
          PlanRecommended: PlanRecommended,
          PlanStatus: PlanStatus
        };
        console.log({ upload });

        this.progessStart = 1;
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/event-plans/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        if (PlanId == "" && EventId == "") {
          message += "Event Details Not found.";
        }
        this.sweetAlert("error", message, false);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#all-events-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>